import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  Input,
  Button,
  Field,
  Control,
  StaticButton,
  Span,
  Select,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Tip Calculator",
    link: "/tip-calculator/",
  },
]
const seeAlsoArray = [
  "/bmi-calculator",
  "/date-and-time-calculator",
  "/density-calculator",
  "/fuel-consumption-calculator",
  "/kite-size-calculator",
  "/mortgage-loan-calculator",
  "/percentage-calculator",
  "/rep-max-calculator",
  "/shopping-calculator",
  "/tax-cost-calculator",
]

function TipCalculator(props) {
  let [billamt, setbillamt] = useState("")
  let [serviceQual, setserviceQual] = useState("0")
  let [peopleamt, setpeopleamt] = useState("1")
  let [tip, settip] = useState("")

  let unitSelector = useRef("")

  function billamtC(e) {
    setbillamt(e.target.value)
  }
  function serviceQualC(e) {
    setserviceQual(e.target.value)
  }
  function peopleamtC(e) {
    setpeopleamt(e.target.value)
  }
  //Calculate Tip
  function calculateTip() {
    billamt = Number(billamt)
    peopleamt = Number(peopleamt)
    serviceQual = Number(serviceQual)
    let unit = unitSelector.current.value
    if (billamt <= 0) {
      settip(<strong>Please Enter Your Bill Amount !</strong>)
    } else if (serviceQual === 0) {
      settip(
        <strong>
          Please Choose an Option <br /> Of How was your service
        </strong>
      )
    } else if (peopleamt === "" || peopleamt < 1) {
      settip(
        <strong>
          Please Enter How many people are sharing the bill ! <br />
          Minimum One Person !
        </strong>
      )
    } else {
      var total = (billamt * serviceQual) / peopleamt

      total = Math.round(total * 100) / 100

      total = total.toFixed(2)
      let ToTalPrice = total * peopleamt
      settip(
        <span>
          Tip Per Person : {total} {unit}
          <br />
          Total Tip : {ToTalPrice} {unit}
        </span>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Tip Calculator - Fast online Tip Calculator"
        description="Calculate Tips online, decided on the percentage tip you want to leave, calculate your tip based on the total before tax, Also check and see if there's a service charge."
        keywords={[
          "tip, tip calculate, calculate tip, decided on the percentage tip you want to leave,how mush leave tips, leave tip ?, tip calculation, how calculate tip, fast calculate tip, tip calculator.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Tip Calculator</Title>

        <FlexDiv maxWidth="500px">
          <Span>How much your bill?</Span>
          <Field>
            <Control className="expandedWidth">
              <Input
                className="borderedLeft"
                value={billamt}
                onChange={billamtC}
                type="number"
                placeholder="Bill Amount"
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="unitSelector"
                  ref={unitSelector}
                >
                  <option value="&#36;">&#36;</option>
                  <option value="&#162;">&#162;</option>
                  <option value="&#163;">&#163;</option>
                  <option value="&#165;">&#165;</option>
                  <option value="&#8355;">&#8355;</option>
                  <option value="&#8356;">&#8356;</option>
                  <option value="&euro;">&euro;</option>
                </select>
              </Select>
            </Control>
          </Field>
          <Field>
            <Control>
              <StaticButton>How was your service</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select>
                <select
                  className="staticSelect"
                  aria-label="service"
                  value={serviceQual}
                  onChange={serviceQualC}
                  onBlur={serviceQualC}
                >
                  <option value="0">Choose an Option</option>
                  <option value="0.05">5&#37; &#45; Terrible</option>
                  <option value="0.1">10&#37; &#45; Bad</option>
                  <option value="0.15">15&#37; &#45; It was OK</option>
                  <option value="0.2">20&#37; &#45; Good</option>
                  <option value="0.3">30&#37; &#45; Outstanding</option>
                  <option value="0.4">40&#37; &#45; Outstanding</option>
                  <option value="0.5">50&#37; &#45; Outstanding</option>
                  <option value="0.6">60&#37; &#45; Outstanding</option>
                  <option value="0.7">70&#37; &#45; Outstanding</option>
                  <option value="0.8">80&#37; &#45; Outstanding</option>
                  <option value="0.9">90&#37; &#45; Outstanding</option>
                  <option value="1">100&#37; &#45; Outstanding</option>
                </select>
              </Select>
            </Control>
          </Field>

          <Span>How many people are sharing the bill?</Span>
          <Field>
            <Control className="expandedWidth">
              <Input
                className="borderedLeft"
                value={peopleamt}
                onChange={peopleamtC}
                type="number"
                placeholder="Number of People"
                min="1"
              />
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                people
              </StaticButton>
            </Control>
          </Field>

          <Button
            className="mb-2"
            type="button"
            borderColor="#1da1f2"
            color="white"
            backgroundColor="#1da1f2"
            hoverColor="#1da1f2"
            hoverBorderColor="#1da1f2"
            onClick={calculateTip}
          >
            Calculate!
          </Button>

          <div className="mb-3">
            <p className="h5">{tip}</p>
          </div>
        </FlexDiv>
        <br />
        <h3>How To Calculate Tip?</h3>
        <p>
          Just enter the of the pre-tax bill amount, the general rule is to tip
          15 to 20 percent simply of the pre-tax bill. Them click on the
          calculate button.
          <br />
          but before you calculate the tip you want to give, check and see if
          there's a service charge, in this case, you should not leave any extra
          tip.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default TipCalculator
